/**
 * FavoriteCard
 * 
 * @Author: Focci
 * @Date: 2023-08-22 14:26:50
 * @Last Modified by: Focci
 * @Last Modified time: 2023-08-22 14:26:50
 */

// import PropTypes from 'prop-types'
import { LinkTo } from '@comp/LinkTo'
import clsx from 'clsx'
import AspectRatioCover from '@comp/AspectRatioCover'
import { isArray, times } from 'lodash-es'
import { Skeleton } from '@comp/material'
import { favourite } from '@lib/route/profile'
import { useLocale, useTranslations } from 'next-intl'

export function FavoriteCardSkeleton() {
  return (
    <div className="border rounded-md p-4">
      <div className="flex justify-between">
        <Skeleton variant="text" style={{ fontSize: '1rem', width: '40%' }} />
        <Skeleton variant="text" style={{ fontSize: '1rem', width: '10%' }} />
      </div>
      <div className="grid grid-cols-4 gap-2 mt-4">
        <div className="bg-cf0 col-span-3 rounded-md" />
        <div className="flex flex-col gap-y-2">
          {times(3).fill('').map((_, index) => (
            <div
              key={index}
              className="bg-cf0 rounded-md" 
              style={{ paddingTop: '75%' }} 
            />
          ))}
        </div>
      </div>
    </div>
  )
}

function FavoriteCardBody({ data }) {
  return (
    <div className="grid grid-cols-4 gap-2 mt-4">
      <div className="col-span-3">
        <AspectRatioCover
          subtile
          wrapperClassName="rounded-md"
          className="rounded-md object-cover" 
          src={data.cover}
          width={300}
          height={225}
          alt={data.name}
        />
      </div>
      <div className="flex flex-col gap-y-2">
        {isArray(data.covers) && data.covers.map((url, index) => (
          <div key={index}>
            <AspectRatioCover
              subtile
              wrapperClassName="rounded-md"
              className="rounded-md object-cover" 
              src={url}
              width={300}
              height={225}
              alt={data.name}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

function FavoriteCardNoContent() {
  const tp = useTranslations('favorite')
  return (
    <div 
      className="bg-cf8 flex mt-4 rounded-md flex items-center justify-center" 
      style={{ aspectRatio: '388/221.38' }}
    >
      <div className="flex flex-col items-center">
        <div className="w-12">
          <AspectRatioCover
            src="https://s.hougarden.com/icon/find_agent/category_option_1.png"
            width={120}
            height={108}
            alt="Folder"
          />
        </div>
        <div className="mt-1 text-f.6 text-meta">
          {tp('noContent')}
        </div>
      </div>
    </div>
  )
}

export default function FavoriteCard({
  data = {},
  className = ''
}) {
  const locale = useLocale()

  return (
    <LinkTo 
      href={favourite(data.id, locale)} 
      className={clsx(['flex w-full h-full', className])}
    >
      <div className="border rounded-md p-4 w-full">
        <div className="flex justify-between items-center gap-2">
          <b className="text-base line-clamp-1">{data.name}</b>
          <span className="bg-c3b px-2 py-0.5 rounded-sm text-white text-f.6">
            {data.homeCount}
          </span>
        </div>
        {data.cover && data.covers?.length > 0 && (
          <FavoriteCardBody data={data} />
        )}
        {!(data.cover && data.covers?.length > 0) && (
          <FavoriteCardNoContent />
        )}
      </div>
    </LinkTo>
  )
}

// FavoriteCard.propTypes = {
//   className: PropTypes.string,
// }
