/**
 * 搜索条
 * 
 * @Author: Focci
 * @Date: 2023-08-21 09:50:03
 * @Last Modified by: Focci
 * @Last Modified time: 2023-08-21 09:50:03
 */

'use client'

// import PropTypes from 'prop-types'
import dynamic from 'next/dynamic'
import useProgressRouter from '@hook/useProgressRouter'
import { useLocale, useTranslations } from 'next-intl'
import { houseSearchType } from '@lib/config'
import { useCallback, useState } from 'react'
import * as routeAgent from '@lib/route/agent'
import * as routePS from '@lib/route/productspec'
import SearchBarTab from './SearchBarTab'

function InputSkeleton() {
  return (
    <div className="border rounded-sm h-12 md:h-16" />
  )
}

const SearchBarAutoComplete = dynamic(() => import('./SearchBarAutoComplete'), {
  ssr: false,
  loading: () => <InputSkeleton />
})

const SearchBarForSchool = dynamic(() => import('./SearchBarForSchool'), {
  ssr: false,
  loading: () => <InputSkeleton />
})

export default function SearchBar() {
  const locale = useLocale()
  const tu = useTranslations('userGuide')

  const { routerPush } = useProgressRouter()
  const [type, setType] = useState(houseSearchType.residential)
  
  const handleSearchTabChane = useCallback(({ value, redirect }) => {
    if(!redirect) {
      setType(value)
    } else if(value === 'agent') {
      routerPush(routeAgent.findAnAgent(locale))
    } else if(value === 'productspec') {
      routerPush(routePS.home(locale))
    }
  }, [routerPush, locale])

  return (
    <div
      className="
        bg-white shadow-simple border border-ce6 rounded-md relative z-10
        px-2 md:px-4 lg:px-6 xlpx-10 pt-2 md:pt-4 lg:pt-6 pb-4 -mt-8 md:-mt-[4.5rem]
      "
    >
      <SearchBarTab 
        value={type}
        onChange={handleSearchTabChane}
      />
      <div 
        className="-mt-px"
        data-userguide-title={tu('title1')}
        data-userguide-description={tu('des1')}
        data-userguide-side="bottom"
      >
        {type === 'school' && <SearchBarForSchool />}
        {type !== 'school' && <SearchBarAutoComplete type={type} />}
      </div>
    </div>
  )
}

// SearchBar.propTypes = {
//   mode: PropTypes.string,
// }
