/**
 * Page views tracking
 * 
 * @Author: Focci
 * @Date: 2023-10-24 17:20:01
 * @Last Modified by: Focci
 * @Last Modified time: 2023-10-24 17:20:01
 */

'use client'

import PropTypes from 'prop-types'
import useLogin from '@hook/useLogin'
import { isWindow } from '@lib/utils'
import { isArray } from 'lodash-es'
import { useEffect, useMemo } from 'react'
import { getPageViewsParam } from './lib'

export default function UserTrackingPageViews({
  type = '',
  data,
}) {
  const { user } = useLogin()
  const query = useMemo(
    () => getPageViewsParam(type, data || {}, user), 
    [type, data, user]
  )
  
  useEffect(() => {
    isWindow && isArray(window.dataLayer) && window.dataLayer.push(query)
  }, [query])
}

UserTrackingPageViews.propTypes = {
  type: PropTypes.string,
  data: PropTypes.object,
}
