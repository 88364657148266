/**
 * Pagination
 * 
 * @Author: Focci
 * @Date: 2023-06-19 14:34:09
 * @Last Modified by: Focci
 * @Last Modified time: 2023-06-19 14:34:09
 */

'use client'

import PropTypes from 'prop-types'
import { LinkTo } from '@comp/LinkTo'
import clsx from 'clsx'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { useCallback } from 'react'
import { ButtonBase, usePagination } from '@comp/material'
import { useTranslations } from 'next-intl'
import { isFunction } from 'lodash-es'

function Pagination({
  className = '', // 最外层 className
  pageSize = 20,
  page = 1,
  total = 0,
  getItemUrl = () => { },
  pageClassName = 'inline-flex items-center h-8 px-3 rounded-sm text-f.8',
  activatedPageClassName = 'bg-primary text-white',
  onChange,
  showScrollTopButton = false,
  ...props
}) {
  const tc = useTranslations('common')
  const count = Math.ceil(total / pageSize)
  const defaultPage = page || 1
  const { items } = usePagination({ ...props, page: defaultPage, count })

  const hasClick = isFunction(onChange)
  const Trigger = hasClick ? ButtonBase : LinkTo

  const handleScrollTop = useCallback(() => {
    document.documentElement.scrollIntoView({ behavior: 'smooth' })
  }, [])

  if (!count) return null

  return (
    <div className={clsx(['flex justify-center gap-1', className])}>
      <ul className="flex items-center gap-0.5">
        {items.map(({ page: pageIndex, type, disabled }, index) => {
          let children = null
          const isPage = type === 'page'
          const isEllipsis = type === 'start-ellipsis' || type === 'end-ellipsis'

          if (isEllipsis) {
            children = '…'
          } else if (isPage) {
            children = (
              <Trigger
                className="rounded-sm"
                {...(hasClick
                  ? { onClick: () => onChange({ pageIndex, type }) }
                  : { href: getItemUrl({ type, page: pageIndex }) }
                )}
              >
                <span
                  className={clsx([
                    pageClassName,
                    defaultPage === pageIndex && activatedPageClassName
                  ])}
                >
                  {pageIndex}
                </span>
              </Trigger>
            )
          } else if (!disabled) {
            children = (
              <Trigger
                className="rounded-sm whitespace-nowrap"
                {...(hasClick
                  ? { onClick: () => onChange({ pageIndex, type }) }
                  : { href: getItemUrl({ type, page: pageIndex }) }
                )}
                aria-label={type === 'next' ? 'next page' : 'previous page'}
              >
                <span className={clsx([pageClassName, 'border'])}>
                  {type === 'next' && <ArrowForwardIosIcon />}
                  {type === 'previous' && <ArrowBackIosNewIcon />}
                </span>
              </Trigger>
            )
          }

          return (
            <li
              key={index}
              className={clsx([
                'items-center leading-none bg-white hover:bg-cf0',
                (isPage || isEllipsis) && 'md:flex'
              ])}
            >
              {children}
            </li>
          )
        })}
      </ul>
      {showScrollTopButton && (
        <ButtonBase onClick={handleScrollTop}>
          <div className="flex items-center leading-none bg-white hover:bg-cf0">
            <span className={clsx([pageClassName, 'border whitespace-nowrap'])}>
              {tc('top')}
            </span>
          </div>
        </ButtonBase>
      )}
    </div>
  )
}

Pagination.propTypes = {
  className: PropTypes.string,
  pageSize: PropTypes.number,
  page: PropTypes.number,
  total: PropTypes.number,
  getItemUrl: PropTypes.func,
  pageClassName: PropTypes.string,
  activatedClassName: PropTypes.string,
  onClick: PropTypes.func,
  showScrollTopButton: PropTypes.bool,
}

export default Pagination
