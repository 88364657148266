/**
 * ClaimedCard
 * 
 * @Author: Focci
 * @Date: 2023-08-22 08:55:51
 * @Last Modified by: Focci
 * @Last Modified time: 2023-08-22 08:55:51
 */

import PropTypes from 'prop-types'
import AspectRatioCover from '@comp/AspectRatioCover'
import clsx from 'clsx'
import SVGLocale from '@img/locale.svg'
import SVGDollar from '@img/dollar.svg'
import SVGRise from '@img/rise.svg'
import SVGDrop from '@img/drop.svg'
import LinkPropertyDetail from '@comp/LinkPropertyDetail'
import { formatLang } from '@lib/datetime'
import { useLocale, useTranslations } from 'next-intl'
import { Skeleton } from '@comp/material'

export function ClaimedCardSkeleton() {
  return (
    <div className="border rounded-md pb-px">
      <div className="w-full">
        <div
          className="bg-cf0 rounded-t-md"
          style={{
            aspectRatio: '430 / 360'
          }}
        />
      </div>
      <div className="px-2 pt-1 pb-2">
        <div className="mt-2">
          <Skeleton variant="text" style={{ width: '80%' }} />
        </div>
        <div className="mt-2">
          <Skeleton variant="text" style={{ width: '50%', fontSize: '1rem' }} />
          <Skeleton variant="text" style={{ width: '70%' }} />
        </div>
      </div>
    </div>
  )
}

export default function ClaimedCard({
  data = {},
  propertyIcons = '',
  inline = false
}) {
  const locale = useLocale()
  const th = useTranslations('home')

  return (
    <LinkPropertyDetail slug={data.slug} className="flex h-full">
      <div 
        className={clsx([
          'rounded-md w-full',
          inline ? 'flex' : 'border'
        ])}
      >
        <div 
          className={clsx([
            'overflow-hidden',
            inline ? 'rounded-l-md w-2/5' : 'rounded-t-md'
          ])}
        >
          <AspectRatioCover
            hoverZoomIn
            className={clsx([
              'object-cover', 
              inline ? 'rounded-l-md' : 'rounded-t-md'
            ])} 
            src={data?.image}
            alt={data.address}
            width={430}
            height={360}
          />
        </div>
        <div className={clsx(['px-2 pt-1 pb-2 flex-auto', inline && 'bg-cf0 rounded-r-md'])}>
          <div className="flex items-center gap-x-2 mt-2">
            <SVGLocale className="text-primary shrink-0" width=".7rem" />
            <span className="leading-tight line-clamp-1">
              {data.address}
            </span>
          </div>
          {data.avm && (
            <div>
              <div className="flex items-center gap-x-2 mt-1">
                <SVGDollar className="text-primary shrink-0" width=".7rem" />
                <div className="flex items-center gap-x-1 whitespace-nowrap">
                  <b className="text-f.9">{`${data.avm.low} - ${data.avm.high}`}</b>
                  {data.avm.trend_monthly === 'down' && <SVGDrop className="text-success" />}
                  {data.avm.trend_monthly === 'up' && <SVGRise className="text-error" />}
                </div>
              </div>
              {propertyIcons}
              <div className="ml-5 mt-1 text-primary">
                {formatLang(data.avm.avm_time, th('updateDatetime'), locale)}
              </div>
            </div>
          )}
        </div>
      </div>
    </LinkPropertyDetail>
  )
}

ClaimedCard.propTypes = {
  data: PropTypes.object,
  propertyIcons: PropTypes.node,
  inline: PropTypes.bool,
}
