/**
 * 快速导航
 * 
 * @Author: Focci
 * @Date: 2023-08-21 09:48:31
 * @Last Modified by: Focci
 * @Last Modified time: 2023-08-21 09:48:31
 */

// import PropTypes from 'prop-types'
import { LinkTo } from '@comp/LinkTo'
import { list as routeList } from '@lib/route/news'
import { suburbs } from '@lib/route/house'
import { findAnAgent } from '@lib/route/agent'
import { houseSearchType } from '@lib/config'
import { urls } from '@lib/parse'
import { isAU } from '@lib/utils'
import navImg from '@img/home/nav.png'
import Scrollbar from '@comp/Scrollbar'
import SVGNews from '@img/home/news.svg'
import SVGMore from '@img/home/more.svg'
import SVGEstimate from '@img/home/estimate.svg'
import SVGRental from '@img/home/rental.svg'
import SVGProperty from '@img/home/property.svg'
import { useLocale, useTranslations } from 'next-intl'
import HomeItemScoff from '../HomeItemScoff'

function BarItem({
  label = '',
  Icon,
  gradientColor = '',
  url = ''
}) {
  return (
    <LinkTo href={url}>
      <div 
        className="h-12 flex items-center justify-between px-4 text-white"
        style={{ background: `linear-gradient(90deg,${gradientColor})` }}
      >
        <div className="flex items-center gap-x-2">
          <Icon />
          <span className="text-base">{label}</span>
        </div>
        <SVGMore />
      </div>
    </LinkTo>
  )
}

function NavIcon({
  label = '',
  url = '',
  position = '',
}) {
  return (
    <LinkTo href={url}>
      <div className="flex flex-col gap-y-1 items-center">
        <div className="w-12 h-8 relative">
          <span
            className="w-24 h-16 block scale-50 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
            style={{
              backgroundImage: `url(${navImg.src})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: position,
            }} 
          />
        </div>
        <span>{label}</span>
      </div>
    </LinkTo>
  )
}

export default function NavBarBody() {
  const locale = useLocale()
  const tm = useTranslations('menu')
  const tn = useTranslations('news')
  
  return (
    <HomeItemScoff>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-1">
        <BarItem 
          label={tn('headline')}
          gradientColor="#ff8d4d,#f8b231"
          Icon={SVGNews}
          url={routeList('all', 1, locale)}
        />
        <BarItem
          label={tm('houseForSale')}
          gradientColor="#25abf9,#43d1fc"
          Icon={SVGProperty}
          url={urls.listingDefault(houseSearchType.residential, locale)}
        />
        <BarItem
          label={tm('rental')}
          gradientColor="#fbb762,#ffd191"
          Icon={SVGRental}
          url={urls.listingDefault(houseSearchType.rental, locale)}
        />
        <BarItem
          label={tm('propertyEstimate')}
          gradientColor="#ff6969,#fbb0a0"
          Icon={SVGEstimate}
          url={urls.listingDefault(houseSearchType.estimate, locale)}
        />
      </div>
      <div>
        <Scrollbar autoHeight>
          <div className="mt-6 flex items-center gap-x-6 justify-start lg:justify-around">
            {!isAU && (
              <NavIcon 
                label={tm('development')} 
                position="-5px -5px"
                url={urls.listingDefault(houseSearchType.development, locale)}
              />
            )}
            <NavIcon 
              label={tm('agent')} 
              position="-135px -5px"
              url={findAnAgent(locale)}
            />
            <NavIcon 
              label={tm('sold')} 
              position="-5px -95px"
              url={urls.listingDefault(houseSearchType.sold, locale)}
            />
            <NavIcon 
              label={tm('newHouse')} 
              position="-135px -95px"
              url={urls.listingDefault(houseSearchType.newHomes, locale)}
            />
            <NavIcon 
              label={tm('mapFindAHouse')} 
              position="-5px -185px"
              url={urls.listingDefault(houseSearchType.property, locale, true)}
            />
            {!isAU && (
              <NavIcon 
                label={tm('commercialProperty')} 
                position="-265px -5px"
                url={urls.listingDefault(houseSearchType.commercialForSale, locale)}
              />
            )}
            {!isAU && (
              <NavIcon 
                label={tm('ruralTrade')} 
                position="-135px -185px"
                url={urls.listingDefault(houseSearchType.rural, locale)}
              />
            )}
            {!isAU && (
              <NavIcon 
                label={tm('areaAnalysis')} 
                position="-265px -95px"
                url={suburbs(undefined, locale)}
              />
            )}
          </div>
        </Scrollbar>
      </div>
    </HomeItemScoff>
  )
}
